import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container, FormGroup, Input } from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';

import { ModalScreen } from '../Modal/Screen/Screen';
import { ModalScreenHeader } from '../Modal/Screen/Header';
import { FormLabel } from '../Form/Label';
import { ButtonPill } from '../Button';

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		piPoints: {
			type: 'number',
		},
		chips: {
			type: 'number',
		},
	},
	required: [
		'chips', 'piPoints',
	],
};

export const ResourceAccessPriceModal = ({ isOpen, closeModal, price, onBuy }) => {
	const { t } = useTranslation();

	const [amounts, setAmounts] = useState({
		piPoints: 0,
		chips: price,
	});

	const handlePointsChange = useCallback((e) => {
		const { name, value } = e.target;

		const parsedValue = value ? parseInt(value, 10) : 0;

		if (parsedValue > price || parsedValue < 0) {
			return;
		}

		setAmounts({
			piPoints: name === 'piPoints'
				? parsedValue
				: price - parsedValue,
			chips: name === 'chips'
				? parsedValue
				: price - parsedValue,
		});
	}, [price]);

	return (
		<ModalScreen
			bodyClassName="bg-tertiary py-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('RessourceAccessPriceModal.enterPrice')}
				/>
			)}
			isOpen={isOpen}
			onClose={closeModal}
			size="md"
		>
			<Container className="my-5 mx-3">
				<Row className="mb-3">
					<Col>
						<p>
							{t('RessourceAccessPriceModal.specifyPrice')}{price} {t('RessourceAccessPriceModal.points')}
						</p>
					</Col>
				</Row>
				<Form
					data={amounts}
					onSubmit={() => onBuy(amounts)}
					schema={formSchema}
				>
					<FormGroup>
						<FormLabel>Chips</FormLabel>
						<Field
							component={Input}
							type="number"
							name="chips"
							value={amounts.chips}
							onChange={handlePointsChange}
						/>
						<FieldError name="chips" />
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('RessourceAccessPriceModal.pointsLabel')}</FormLabel>
						<Field
							component={Input}
							type="number"
							name="piPoints"
							value={amounts.piPoints}
							onChange={handlePointsChange}
						/>
						<FieldError name="piPoints" />
					</FormGroup>
					<ButtonPill
						className="mt-2"
						color="primary"
					>
						{t('RessourceAccessPriceModal.rent')}
					</ButtonPill>
				</Form>
			</Container>
		</ModalScreen>
	);
};

ResourceAccessPriceModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	price: PropTypes.number.isRequired,
	onBuy: PropTypes.func.isRequired,
};

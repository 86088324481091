import React, { lazy, Suspense, useMemo } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
} from 'react-router-dom';

import { NoSidebar } from './layout-blueprints';

import { postPageViewData } from './api/pageview/pageview';
import { Role, useAuthentication } from './components/Authentication/Authentication';
import RouteIfAuthenticated from './components/Authentication/RouteIfAuthenticated';
import RouteIfRoleMatch from './components/Authentication/RouteIfRoleMatch';
import NavCount from './components/NavCount/NavCount';
import { SlackConnect } from './components/Slack/Connect';
import { BlankPage } from './layout-blueprints/BlankPage';
import { EmbedPage } from './layout-blueprints/EmbedPage';
import { AdminPath, PublicPathPrefix, DevPath, getRedirectToLink, Path, RedirectsFromPath } from './RoutePath';
import { ViewComvisionHome } from './views/Home/Comvision';
import ViewHome from './views/Home/Home';
import { ViewComvisionHomeLanding } from './views/Home/ComvisionLanding';

const ViewChannel = lazy(() => import('./views/Channel/Channel'));
const ViewChannelWatch = lazy(() => import('./views/Channel/Watch/Watch'));
const ViewChannelWatchEmbed = lazy(() => import('./views/Channel/Watch/WatchEmbed'));
const ViewChannelWatchEmbedTest = lazy(() => import('./views/Channel/Watch/WatchEmbedTest'));
const ViewChannelFrame = lazy(() => import('./views/Channel/Frame'));
const ViewChannelFrameTest = lazy(() => import('./views/Channel/FrameTest'));
const ViewCookiePolicy = lazy(() => import('./views/LegalNotice/CookiePolicy'));
const ViewSearch = lazy(() => import('./views/Search/Search'));
const ViewTermsAndPrivacy = lazy(() => import('./views/LegalNotice/TermsAndPrivacy'));
const ViewVod = lazy(() => import('./views/VodView/VodView'));
const ViewEmbedVod = lazy(() => import('./views/EmbedVod/EmbedVod'));
const Checkout = lazy(() => import('./views/Checkout/Checkout'));
const Donate = lazy(() => import('./views/Donate/Donate'));
const ViewStudio = lazy(() => import('./views/Studio/Studio'));
const ViewStudioElements = lazy(() => import('./views/Studio/StudioElementsWrapper'));
const ViewStudioActivity = lazy(() => import('./views/Studio/StudioActivity'));
const ViewStudioChat = lazy(() => import('./views/Studio/StudioChatWrapper'));
const ViewStudioParticipants = lazy(() => import('./views/Studio/StudioParticipantsWrapper'));
const ViewStudioTools = lazy(() => import('./views/Studio/StudioToolsWrapper'));
const ViewStudioTemplates = lazy(() => import('./views/Studio/StudioTemplatesWrapper'));
const ViewStudioInactive = lazy(() => import('./views/Studio/Inactive'));
const ViewParticipant = lazy(() => import('./views/Participant/Participant'));
const ViewOperator = lazy(() => import('./views/Operator/Operator'));
const ViewLive = lazy(() => import('./views/Live/Live'));
const ViewLiveFrame = lazy(() => import('./views/Live/Frame'));
const ViewStudioDevReactVideo = lazy(() => import('./views/Studio/DevReactVideo'));
const ViewParticipantDevReactVideo = lazy(() => import('./views/Participant/DevReactVideo'));
const ViewMediaTest = lazy(() => import('./views/MediaTest/MediaTest'));
const ViewMediaTestWebrtc = lazy(() => import('./views/MediaTest/Webrtc'));
const ViewHelpCenter = lazy(() => import('./views/HelpCenter/HelpCenter'));
const ViewUserGuide = lazy(() => import('./views/UserGuide/UserGuide'));
const ViewSurvey = lazy(() => import('./views/Survey/Survey'));
const ViewStudioAttendanceSurvey = lazy(() => import('./components/Survey/Attendance/Attendance'));
const ViewPost = lazy(() => import('./views/PostPageView/PostPageView'));
const RoutesAdmin = lazy(() => import('./RoutesAdmin'));
const ViewWatchVideos = lazy(() => import('./views/WatchVideos/WatchVideos'));
const ViewMeetTheBees = lazy(() => import('./views/Meet/MeetTheBees'));
const EditBlog = lazy(() => import('./views/Blog/EditBlog'));
const Blog = lazy(() => import('./views/Blog/Blog'));
const ViewNewUserPage = lazy(() => import('./views/NewUser/NewUserPage'));
const ViewPricesPage = lazy(() => import('./views/Prices/PricesPage'));
const NewsletterUnsubscribe = lazy(() => import('./views/Newsletter/Unsubscribe'));
const Newsletter = lazy(() => import('./views/Newsletter/Newsletter'));
// const RoutesAdminAnalytics = lazy(() => import('./RoutesAdminAnalytics'));

function usePageViews() {
	const location = useLocation();
	const page = location.pathname;
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			ReactGA.set({ page });
			ReactGA.pageview(page);
			postPageViewData(page);
		}, 500);
		return () => { clearTimeout(timeout); };
	}, [page]);
}

const RedirectRoutes = () => (
	<Switch>
		{RedirectsFromPath.map((path) => (
			<Route
				key={path}
				path={path}
				render={(props) => (
					// eslint-disable-next-line react/prop-types
					<Redirect to={getRedirectToLink(path, props.match.params)} />
				)}
			/>
		))}
	</Switch>
);

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const Routes = () => {
	const { t } = useTranslation();
	usePageViews();

	const { isLoggedIn } = useAuthentication();

	const isBeeyouOrLoggedIn = isBeeyou || isLoggedIn;

	const isComvision = useMemo(() => (
		!isLoggedIn && import.meta.env.VITE_PROJECT === 'comvision'
	),
	[isLoggedIn]);

	return (
		<NavCount>
			<Suspense
				fallback={(
					<div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
						<div className="w-50 mx-auto">
							{t('Src.Routes.loading')}
						</div>
					</div>
				)}
			>
				<Switch>
					<Route path={RedirectsFromPath}>
						<RedirectRoutes />
					</Route>
					<RouteIfRoleMatch
						component={RoutesAdmin}
						path={AdminPath.ADMIN_ROOT}
						role={Role.ADMIN}
					/>
					<Route path={PublicPathPrefix}>
						<Switch>
							<Route
								path={[
									Path.MEDIATEST,
									Path.WEBRTCTEST,
									Path.LEGAL_COOKIES,
									Path.LEGAL_TERMS,
									Path.SEARCH,
									Path.HELP_USERGUIDE,
									Path.HELP_ANCHOR,
									Path.HELP,
									Path.HOME_WATCH_VIDEOS,
									Path.HOME_MEET_BEES,
									isLoggedIn && Path.NEWUSERINFO,
									Path.PRICES,
								].filter(Boolean)}
							>
								<NoSidebar>
									<Switch>
										{isBeeyouOrLoggedIn
										&& (<Route path={Path.HOME_WATCH_VIDEOS} component={ViewWatchVideos} />)}
										{isBeeyouOrLoggedIn
										&& (<Route path={Path.HOME_MEET_BEES} component={ViewMeetTheBees} />)}
										{isLoggedIn
											? <Route path={Path.NEWUSERINFO} component={ViewNewUserPage} />
											: null}
										<Route path={Path.PRICES} component={ViewPricesPage} />
										<Route path={Path.MEDIATEST} component={ViewMediaTest} />
										<Route path={Path.WEBRTCTEST} component={ViewMediaTestWebrtc} />
										<Route path={Path.LEGAL_COOKIES} component={ViewCookiePolicy} />
										<Route path={Path.LEGAL_TERMS} component={ViewTermsAndPrivacy} />
										<Route path={Path.SEARCH} component={ViewSearch} />
										<Route exact path={Path.HELP_USERGUIDE} component={ViewUserGuide} />
										<Route path={Path.HELP_ANCHOR} component={ViewHelpCenter} />
										<Route exact path={Path.HELP} component={ViewHelpCenter} />
									</Switch>
								</NoSidebar>
							</Route>
							<Route
								path={[
									Path.BLOG_PREVIEW,
									Path.BLOG_EDIT,
								]}
							>
								<NoSidebar>
									<Switch>
										<Route path={Path.BLOG_PREVIEW} component={Blog} />
										<RouteIfAuthenticated path={Path.BLOG_EDIT} exact component={EditBlog} />
									</Switch>
								</NoSidebar>
							</Route>
							<Route path={Path.EMBED_VOD}>
								<EmbedPage>
									<ViewEmbedVod />
								</EmbedPage>
							</Route>
							<Route
								path={[
									Path.CHECKOUT,
									Path.DONATE,
									Path.SLACK,
									!isLoggedIn && isBeeyou && Path.NEWUSERINFO,
									'/pi-success', // TODO REMOVE ?
								].filter(Boolean)}
							>
								<NoSidebar>
									<Switch>
										<RouteIfAuthenticated path={Path.CHECKOUT} component={Checkout} />
										<RouteIfAuthenticated path={Path.DONATE} component={Donate} />
										{!isLoggedIn && isBeeyou
											? <Route path={Path.NEWUSERINFO} component={ViewNewUserPage} />
											: null}
										<Route path={Path.SLACK_CONNECT} component={SlackConnect} />
									</Switch>
								</NoSidebar>
							</Route>
							<Route
								path={[
									Path.SURVEY,
									Path.SURVEY_STUDIO_ATTENDANCE,
								]}
							>
								<BlankPage>
									<Switch>
										<Route
											path={Path.SURVEY_STUDIO_ATTENDANCE}
											component={ViewStudioAttendanceSurvey}
										/>
										<RouteIfAuthenticated path={Path.SURVEY} component={ViewSurvey} />
									</Switch>
								</BlankPage>
							</Route>
							<Route
								path={[
									Path.NEWSLETTER_UNSUBSCRIBE,
									Path.NEWSLETTER,
								]}
							>
								<NoSidebar>
									<Switch>
										<RouteIfAuthenticated
											path={Path.NEWSLETTER_UNSUBSCRIBE}
											component={NewsletterUnsubscribe}
										/>
										<RouteIfAuthenticated
											path={Path.NEWSLETTER}
											component={Newsletter}
										/>
									</Switch>
								</NoSidebar>
							</Route>
							<Route
								path={[
									Path.STUDIO,
								]}
							>
								<NoSidebar isMobileLayout over isStudioRoute noSearch>
									<Switch>
										<RouteIfAuthenticated path={Path.STUDIO} component={ViewStudioInactive} />
									</Switch>
								</NoSidebar>
							</Route>
							{/* last one /bytv/:anchor to avoid conflicts */}
							<Route
								path={[
									Path.HOME,
								]}
							>
								<Switch>
									{isComvision ? (
										<Route path={Path.HOME} component={ViewComvisionHome} />
									) : (
										<NoSidebar>
											<Route path={Path.HOME} component={ViewHome} />
										</NoSidebar>
									)}
								</Switch>
							</Route>
						</Switch>
					</Route>
					<Route
						path={[
							DevPath.DEV_STUDIO,
							DevPath.DEV_STUDIO_PARTICIPANT,
						]}
					>
						<NoSidebar>
							<Switch>
								<RouteIfAuthenticated
									path={DevPath.DEV_STUDIO}
									component={ViewStudioDevReactVideo}
								/>
								<RouteIfAuthenticated
									path={DevPath.DEV_STUDIO_PARTICIPANT}
									component={ViewParticipantDevReactVideo}
								/>
							</Switch>
						</NoSidebar>
					</Route>
					<Route path={Path.STUDIO_VIEWER_FRAME}>
						<EmbedPage>
							<ViewLiveFrame />
						</EmbedPage>
					</Route>
					<Route
						path={[
							Path.STUDIO_HOST_ELEMENTS,
							Path.STUDIO_ACTIVITY,
							Path.STUDIO_CHAT,
							Path.STUDIO_PARTICIPANTS,
							Path.STUDIO_TEMPLATES,
							Path.STUDIO_TOOLS,
						]}
					>
						<RouteIfAuthenticated
							path={Path.STUDIO_HOST_ELEMENTS}
							component={ViewStudioElements}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_ACTIVITY}
							component={ViewStudioActivity}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_CHAT}
							component={ViewStudioChat}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_PARTICIPANTS}
							component={ViewStudioParticipants}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_TEMPLATES}
							component={ViewStudioTemplates}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_TOOLS}
							component={ViewStudioTools}
						/>
					</Route>
					<Route
						path={[
							Path.STUDIO_HOST,
							Path.STUDIO_OPERATOR,
							Path.STUDIO_HOST_ELEMENTS,
						]}
					>
						<NoSidebar isMobileLayout over isStudioRoute>
							<Switch>
								<RouteIfAuthenticated path={Path.STUDIO_HOST} component={ViewStudio} />
								<RouteIfAuthenticated path={Path.STUDIO_OPERATOR} component={ViewOperator} />
							</Switch>
						</NoSidebar>
					</Route>
					<Route
						path={[
							Path.STUDIO_PARTICIPANT,
							Path.STUDIO_VIEWER,
						]}
					>
						<NoSidebar isMobileLayout over isStudioRoute noSearch>
							<Switch>
								<Route path={Path.STUDIO_PARTICIPANT} component={ViewParticipant} />
								<Route path={Path.STUDIO_VIEWER} component={ViewLive} />
							</Switch>
						</NoSidebar>
					</Route>
					<Route path={Path.CHANNEL_FRAME}>
						<EmbedPage>
							<ViewChannelFrame />
						</EmbedPage>
					</Route>
					<Route
						path={[
							DevPath.CHANNEL_FRAME_TEST,
						]}
					>
						<NoSidebar>
							<ViewChannelFrameTest />
						</NoSidebar>
					</Route>
					{/* Because channel pages can override defaults routes,
					keep them after everything else */}
					<Route path={Path.WATCH_LINK_EMBED}>
						<EmbedPage>
							<ViewChannelWatchEmbed />
						</EmbedPage>
					</Route>
					<Route
						path={[
							Path.CHANNEL,
							Path.WATCH_LINK,
							DevPath.WATCH_LINK_EMBED_TEST,
							Path.POST,
							Path.VOD,
						]}
					>
						<NoSidebar isMobileLayout over>
							<Switch>
								<Route path={DevPath.WATCH_LINK_EMBED_TEST} component={ViewChannelWatchEmbedTest} />
								<Route path={Path.WATCH_LINK} component={ViewChannelWatch} />
								<Route path={Path.VOD} component={ViewVod} />
								<Route path={Path.POST} component={ViewPost} />
								<Route path={Path.CHANNEL} component={ViewChannel} />
							</Switch>
						</NoSidebar>
					</Route>
					{/* ROOT last one to avoid "exact" */}
					<Route path={Path.ROOT}>
						{isComvision ? (
							<Route path={Path.ROOT} component={ViewComvisionHomeLanding} />
						) : (
							<NoSidebar>
								<Route path={Path.ROOT} component={ViewHome} />
							</NoSidebar>
						)}
					</Route>
				</Switch>
			</Suspense>
		</NavCount>
	);
};

export default Routes;

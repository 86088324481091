import React, {
	Suspense,
	lazy,
	useCallback,
	useContext,
	useMemo,
	useState,
	useEffect,
} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import { useAuthentication } from '../Authentication/Authentication';
import { LoginNotificationRouting } from './NotificationRouting';
import { useQueryParams, useUpdateQueryParams } from '../../lib/hooks';
import { LoginOrganizationChallengeModal } from './OrganizationChallengeModal';

const LoginModal = lazy(() => import('./Modal'));
const SignupModal = lazy(() => import('../Signup/Modal'));
const ModalIntro = lazy(() => import('./ModalIntro'));

export const LoginContext = React.createContext();

export const useLogin = () => useContext(LoginContext);

export const LoginModalType = {
	LOGIN: 'LOGIN',
	ORGANIZATION_CHALLENGE: 'ORGANIZATION_CHALLENGE',
	INTRO: 'INTRO',
	SIGNUP: 'SIGNUP',
	ROUTING: 'ROUTING',
};

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const LOCAL_STORAGE_DISCARD_ROUTING_KEY = 'beeyou.discardRouting';

const getSavedDiscardRouting = () => JSON.parse(
	localStorage.getItem(LOCAL_STORAGE_DISCARD_ROUTING_KEY),
);

const savedDiscardRouting = (value) => localStorage.setItem(
	LOCAL_STORAGE_DISCARD_ROUTING_KEY,
	value,
);

const LoginProvider = ({ children }) => {
	const {
		isLoggedIn,
		logout,
		cancelLoginChallengeSelectOrganization,
		loginChallengeSelectOrganization,
	} = useAuthentication();
	const [modalOpen, setModalOpen] = useState();
	const [modalOptions, setModalOptions] = useState();
	const [isFirstLogin, setIsFirstLogin] = useState(false);
	const [shouldShowRoutingModalAfterLogin, setShouldShowLoginModalAfterLogin] = useState(false);
	const [shouldShowIntroModalAfterLogin, setShouldShowIntroModalAfterLogin] = useState(false);

	const query = useQueryParams();
	const updateQuery = useUpdateQueryParams();

	const isRoutingPending = !!modalOpen;
	const isLoginComplete = !!(isLoggedIn && !isRoutingPending);

	const openLogin = useCallback((params) => {
		setShouldShowLoginModalAfterLogin(params?.showRoutingModalAfterLogin);
		setIsFirstLogin(false);
		setModalOpen(LoginModalType.LOGIN);
	}, []);
	const openIntro = useCallback(() => {
		setModalOpen(LoginModalType.INTRO);
	}, []);
	const openSignup = useCallback((params) => {
		setShouldShowLoginModalAfterLogin(params?.showRoutingModalAfterLogin);
		setShouldShowIntroModalAfterLogin(params?.showIntroModalAfterLogin);
		setIsFirstLogin(true);
		setModalOpen(LoginModalType.SIGNUP);
	}, []);

	const openOrganizationChallenge = useCallback((options) => {
		setModalOpen(LoginModalType.ORGANIZATION_CHALLENGE);
		setModalOptions({ [LoginModalType.ORGANIZATION_CHALLENGE]: options });
	}, []);

	const showRoutingModalOrContinue = useCallback(() => {
		const isRoutingDiscarded = getSavedDiscardRouting();
		setModalOpen(isRoutingDiscarded || !shouldShowRoutingModalAfterLogin
			? undefined : LoginModalType.ROUTING);
	}, [shouldShowRoutingModalAfterLogin]);

	const showIntroModal = useCallback(() => {
		const isRoutingDiscarded = getSavedDiscardRouting();
		setModalOpen(isRoutingDiscarded || !shouldShowIntroModalAfterLogin
			? undefined : LoginModalType.INTRO);
	}, [shouldShowIntroModalAfterLogin]);

	const hideRoutingModalAndSetDiscard = useCallback((discard) => {
		if (discard) savedDiscardRouting(true);
		setModalOpen(undefined);
	}, []);

	const handleCloseRoutingModal = useCallback(() => {
		setModalOpen(undefined);
		setIsFirstLogin(false);
		setShouldShowLoginModalAfterLogin(false);
	}, []);

	const handleCloseOnboardingModal = useCallback(() => {
		setModalOpen(undefined);
		setIsFirstLogin(false);
		setShouldShowLoginModalAfterLogin(false);
	}, []);

	const handleCloseSignupModal = useCallback(() => {
		setModalOpen(undefined);
		setIsFirstLogin(false);
		setShouldShowLoginModalAfterLogin(false);
	}, []);

	const handleSubmitStepOrganizationChallenge = useCallback(async (organizationId) => {
		await loginChallengeSelectOrganization({
			organizationId,
			organizationChallengeToken: (
				modalOptions[LoginModalType.ORGANIZATION_CHALLENGE].organizationChallengeToken
			),
		});
	}, [loginChallengeSelectOrganization, modalOptions]);

	const handleCloseOnboardingChallenge = useCallback(() => {
		cancelLoginChallengeSelectOrganization();
		setModalOpen(undefined);
	}, [cancelLoginChallengeSelectOrganization]);

	useEffect(() => {
		if (modalOpen === LoginModalType.ROUTING && isBeeyou) {
			const toastId = toast(
				<LoginNotificationRouting
					hideRoutingModalAndSetDiscard={hideRoutingModalAndSetDiscard}
					isFirstLogin={isFirstLogin}
					openIntro={openIntro}
				/>,
				{ containerId: 'LoginToastBottomLeft' },
			);

			return () => { toast.dismiss(toastId); };
		}

		return undefined;
	}, [modalOpen, isFirstLogin, openIntro, hideRoutingModalAndSetDiscard]);

	const clearOrganizationQueryParams = useCallback(() => {
		query.delete('open');
		updateQuery(query);
	}, [query, updateQuery]);

	useEffect(() => {
		if (isLoggedIn) {
			setModalOpen(undefined);
			showRoutingModalOrContinue();
		}
	}, [
		isLoggedIn,
		showRoutingModalOrContinue,
		setModalOpen,
	]);

	useEffect(() => {
		if (query.get('open') === 'login') {
			logout();
			openLogin();
			clearOrganizationQueryParams();
		} else if (query.get('open') === 'signup') {
			logout();
			openSignup();
			clearOrganizationQueryParams();
		}
	}, [logout, openLogin, openSignup, clearOrganizationQueryParams, query]);

	const context = useMemo(() => ({
		LoginModalType,
		handleCloseRoutingModal,
		hideRoutingModalAndSetDiscard,
		isLoginComplete,
		isRoutingPending,
		modalOpen,
		openIntro,
		openLogin,
		openSignup,
		setIsFirstLogin,
		shouldShowRoutingModalAfterLogin,
		showRoutingModalOrContinue,
		showIntroModal,
	}), [
		handleCloseRoutingModal,
		hideRoutingModalAndSetDiscard,
		isLoginComplete,
		isRoutingPending,
		modalOpen,
		openIntro,
		openLogin,
		openSignup,
		setIsFirstLogin,
		shouldShowRoutingModalAfterLogin,
		showRoutingModalOrContinue,
		showIntroModal,
	]);

	return (
		<LoginContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === LoginModalType.LOGIN && (
					<LoginModal
						isOpen
						toggle={() => setModalOpen(undefined)}
						showRoutingModalOrContinue={showRoutingModalOrContinue}
						openOrganizationChallenge={openOrganizationChallenge}
					/>
				)}
				{modalOpen === LoginModalType.ORGANIZATION_CHALLENGE && (
					<LoginOrganizationChallengeModal
						isOpen
						modalOptions={modalOptions[LoginModalType.ORGANIZATION_CHALLENGE]}
						onClose={handleCloseOnboardingChallenge}
						onSubmit={handleSubmitStepOrganizationChallenge}
					/>
				)}
				{modalOpen === LoginModalType.SIGNUP && (
					<SignupModal
						isOpen
						toggle={handleCloseSignupModal}
					/>
				)}
				{modalOpen === LoginModalType.INTRO && (
					<ModalIntro
						isOpen
						toggle={() => {
							showRoutingModalOrContinue();
						}}
					/>
				)}
			</Suspense>
			<ToastContainer
				autoClose={30000}
				closeOnClick={false}
				enableMultiContainer
				containerId="LoginToastBottomLeft"
				position={toast.POSITION.BOTTOM_LEFT}
				closeButton={false}
			/>
		</LoginContext.Provider>

	);
};

LoginProvider.propTypes = {
	children: PropTypes.node,
};

LoginProvider.defaultProps = {
	children: undefined,
};

export default React.memo(LoginProvider);

import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePlayerSize } from '@technomiam/react-video';
import { useTranslation } from 'react-i18next';

import { HLSPlayerPlaylist } from '../../HLSPlayer/Playlist';
import { HLSPlayerVideoType, WatchedFromResourceType } from '../../HLSPlayer/HLSPlayer';
import { useStudio } from '../../Studio/Context';
import { useAd } from '../Context';
import { TransactionAdBlockType } from '../../../lib/TransactionAdBlockType';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';

const formatCountdown = (time) => {
	const minutes = Math.floor(time / 60);
	const seconds = time - minutes * 60;

	return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const AdVideoPlayerTooltip = ({
	children,
	playerRef,
}) => {
	const size = usePlayerSize(playerRef);

	return (
		<div className="position-absolute w-100 d-flex justify-content-center zIndex-2">
			<div className="position-absolute ms-2 text-white" style={{ width: size.width }}>
				{children}
			</div>
		</div>
	);
};

AdVideoPlayerTooltip.propTypes = {
	children: PropTypes.node.isRequired,
	playerRef: PropTypes.shape({
		current: PropTypes.instanceOf(Element),
	}).isRequired,
};

export const AdVideoPlayer = ({
	adBlockStudioAdId,
	adBlockType,
	controls,
	handleAdPlaying,
	hideTooltip,
	isRequestingPlay,
	isSkippable,
	onAdLoaded,
	onEnded,
	onTogglePlaying,
	role,
	videos,
	watchedFromOwnerNickname,
	watchedFromVodId,
}) => {
	const { t } = useTranslation();
	const { studio } = useStudio();
	const { adInfo, setCurrentAdsTimeElapsed } = useAd();

	const playerRef = useRef();

	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
	const [timeLeft, setTimeLeft] = useState(Math.round(videos[currentVideoIndex].duration));
	const lastTimeUpdate = useRef(Date.now());

	const currentVideo = useMemo(() => videos[currentVideoIndex], [currentVideoIndex, videos]);
	const ads = useMemo(() => videos.filter(({ type }) => type === 'AD'), [videos]);
	const adIndex = useMemo(() => ads.indexOf(currentVideo), [ads, currentVideo]);
	const isCurrentVideoAd = currentVideo.type === 'AD';
	const adsCount = ads.length;

	const handleVideoChange = useCallback((index) => setCurrentVideoIndex(index), []);

	const handleProgressTimeChange = useCallback((time) => {
		const delta = Date.now() - lastTimeUpdate.current;
		if (delta < 1000) return;

		if (isCurrentVideoAd) {
			setCurrentAdsTimeElapsed((prev) => ({ ...prev, [adIndex]: time }));
			setTimeLeft(Math.round(currentVideo.duration - time));
		} else {
			setTimeLeft(0);
		}

		lastTimeUpdate.current = Date.now();
	}, [isCurrentVideoAd, currentVideo.duration, setCurrentAdsTimeElapsed, adIndex]);

	const nickname = watchedFromOwnerNickname || studio?.owner?.nickname;
	const adsPlayingInfoTranslationKey = !isSkippable && nickname ? 'Ad.Video.Player.userPlayingAdInfo' : 'Ad.Video.Player.adInfo';
	const adsPlayingTranslationKey = !isSkippable && nickname ? 'Ad.Video.Player.userPlayingAd' : 'Ad.Video.Player.ad';

	const watchedFromResourceId = studio?._id || watchedFromVodId;
	const watchedFromResourceType = studio
		? WatchedFromResourceType.STUDIO
		: WatchedFromResourceType.VOD;

	const adBlockInfo = useMemo(() => ({
		studioAd: adBlockStudioAdId || adInfo._id,
		studioRole: role,
		type: adBlockType,
	}), [adBlockStudioAdId, adBlockType, adInfo._id, role]);

	return (
		<div className="overflow-hidden position-relative h-100 w-100">
			{!hideTooltip && (
				<AdVideoPlayerTooltip playerRef={playerRef}>
					{isCurrentVideoAd
						? t(adsPlayingInfoTranslationKey, {
							adCount: adsCount > 1 ? ` (${adIndex + 1}/${adsCount})` : '',
							countdown: formatCountdown(timeLeft),
							nickname,
						})
						: t(adsPlayingTranslationKey, { nickname })}
				</AdVideoPlayerTooltip>
			)}
			<HLSPlayerPlaylist
				adBlockInfo={adBlockInfo}
				controls={controls}
				isRequestingPlay={isRequestingPlay}
				onEnded={onEnded}
				onError={onEnded}
				onProgressTimeChange={handleProgressTimeChange}
				onTogglePlaying={onTogglePlaying}
				onVideoChange={handleVideoChange}
				onVideoLoaded={onAdLoaded}
				onVideoPlaying={handleAdPlaying}
				videoPlayerRef={playerRef}
				videos={videos}
				videoType={HLSPlayerVideoType.AD}
				watchedFromResourceId={watchedFromResourceId}
				watchedFromResourceType={watchedFromResourceType}
			/>
		</div>
	);
};

AdVideoPlayer.propTypes = {
	adBlockStudioAdId: PropTypes.string,
	adBlockType: PropTypes.oneOf(Object.values(TransactionAdBlockType)),
	controls: PropTypes.bool,
	handleAdPlaying: PropTypes.func.isRequired,
	hideTooltip: PropTypes.bool,
	isRequestingPlay: PropTypes.bool.isRequired,
	isSkippable: PropTypes.bool,
	onAdLoaded: PropTypes.func.isRequired,
	onEnded: PropTypes.func.isRequired,
	onTogglePlaying: PropTypes.func,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	videos: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		duration: PropTypes.number.isRequired,
		type: PropTypes.string.isRequired,
	})).isRequired,
	watchedFromOwnerNickname: PropTypes.string,
	watchedFromVodId: PropTypes.string,
};

AdVideoPlayer.defaultProps = {
	adBlockStudioAdId: undefined,
	adBlockType: undefined,
	controls: false,
	hideTooltip: false,
	isSkippable: false,
	onTogglePlaying: undefined,
	role: undefined,
	watchedFromOwnerNickname: undefined,
	watchedFromVodId: undefined,
};

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'reactstrap';
import Slider from 'rc-slider';
import { FaVolumeDown, FaVolumeMute, FaVolumeOff, FaVolumeUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { usePlayerControlsDisplay } from '../Player/ControlsDisplay/Context';
import { isDesktop } from '../../lib/userAgent';

import 'rc-slider/assets/index.css';
import './SoundRange.scss';

const dynamicSoundIcon = (volume) => {
	if (volume === 0) return <FaVolumeOff />;
	if (volume < 50) return <FaVolumeDown />;
	return <FaVolumeUp />;
};

export const SoundRange = ({
	buttonClassName,
	className,
	color,
	handleToggleSound,
	handleChangeVolume,
	volume,
	isMute,
	miniPlayer,
}) => {
	const { t } = useTranslation();
	const { setForceDisplayControls } = usePlayerControlsDisplay();

	const [isSoundRangeOpen, setIsSoundRangeOpen] = useState(false);
	const timeoutRef = useRef();
	const isFirstRender = useRef(true);

	const handleSoundRangeOpen = useCallback((isOpen) => {
		setIsSoundRangeOpen(isOpen);
		if (setForceDisplayControls) setForceDisplayControls(isOpen);
	}, [setForceDisplayControls]);

	/**
	 * Show sound range when volume changes (i.e. with keyboard shortcuts)
	 */
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		clearTimeout(timeoutRef.current);
		handleSoundRangeOpen(true);
		timeoutRef.current = setTimeout(() => {
			handleSoundRangeOpen(false);
		}, 500);
	}, [volume, handleSoundRangeOpen]);

	useEffect(() => () => {
		clearTimeout(timeoutRef.current);
	}, []);

	return (
		<div
			className={clsx('SoundRange d-none d-sm-flex align-items-center', className)}
			onMouseEnter={() => {
				clearTimeout(timeoutRef.current);
				handleSoundRangeOpen(true);
			}}
			onMouseLeave={() => {
				timeoutRef.current = setTimeout(() => {
					handleSoundRangeOpen(false);
				}, 500);
			}}
		>
			<Button
				className={clsx(
					'p-0 btn-no-focus flex-shrink-0 mx-1',
					miniPlayer ? 'd-25' : 'd-30',
					buttonClassName,
				)}
				color={color}
				title={t('SoundRange.SoundRange.sound')}
				onClick={(e) => handleToggleSound() && e.preventDefault()}
			>
				<span className="btn-wrapper--icon">
					{isMute ? <FaVolumeMute /> : dynamicSoundIcon(volume)}
				</span>
			</Button>
			<Slider
				vertical
				className={clsx('CustomSoundInputRange', { isDesktop }, { isOpen: isSoundRangeOpen }, { miniPlayer })}
				min={0}
				max={100}
				value={isMute ? 0 : volume}
				onChange={(value) => handleChangeVolume(value)}
			/>
		</div>
	);
};

SoundRange.propTypes = {
	buttonClassName: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	handleToggleSound: PropTypes.func.isRequired,
	isMute: PropTypes.bool.isRequired,
	volume: PropTypes.number.isRequired,
	handleChangeVolume: PropTypes.func.isRequired,
	miniPlayer: PropTypes.bool,
};

SoundRange.defaultProps = {
	buttonClassName: '',
	className: '',
	color: 'neutral-secondary',
	miniPlayer: false,
};

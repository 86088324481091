import PropTypes from 'prop-types';
import React from 'react';
import { FaCompress, FaExpand, FaPause, FaPlay } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SoundRange } from '../../Sound/SoundRange';

import './ForVideo.scss';

export const ChannelButtonsPanelForVideo = ({
	centerChildren,
	className,
	handleChangeVolume,
	handleClickFullScreen,
	handleTogglePlaying,
	handleToggleSound,
	isFullscreen,
	isPlaying,
	isSoundMuted,
	leftChildren,
	rightChildren,
	volume,
	shareVideoButton,
	miniPlayer,
}) => {
	const { t } = useTranslation();

	return (
		<div className={clsx('ChannelButtonsPanel position-relative p-2 d-flex align-items-center justify-content-between', className)}>
			<div className={`LeftContent d-flex align-items-center ${miniPlayer ? 'miniPlayer' : ''}`}>
				<Button
					className={`p-0 btn-no-focus border-0 ${miniPlayer ? 'd-25' : 'mr-1 d-30'}`}
					color="outline-white"
					title={isPlaying ? t('ChannelButtonsPanel.ForVideo.pause') : t('ChannelButtonsPanel.ForVideo.play')}
					onClick={() => handleTogglePlaying()}
				>
					<span className="btn-wrapper--icon">
						{isPlaying ? <FaPause /> : <FaPlay />}
					</span>
				</Button>
				{leftChildren}
			</div>
			{centerChildren && (
				<div className="CenterContent d-flex align-items-center justify-content-center flex-grow-1">
					{centerChildren}
				</div>
			)}
			<div className="RightContent d-flex align-items-center">
				{shareVideoButton}
				<SoundRange
					buttonClassName="border-0"
					className="mr-1"
					color="outline-white"
					handleChangeVolume={handleChangeVolume}
					volume={volume}
					handleToggleSound={handleToggleSound}
					isMute={isSoundMuted}
					miniPlayer={miniPlayer}
				/>
				{rightChildren}
				{!!handleClickFullScreen && (
					<Button
						className={`p-0 btn-no-focus border-0 ${miniPlayer ? 'd-25' : 'mr-1 d-30'}`}
						color="outline-white"
						title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleScreenMode')}
						onClick={(e) => handleClickFullScreen() && e.preventDefault()}
					>
						<span className="btn-wrapper--icon">
							{isFullscreen ? <FaCompress /> : <FaExpand />}
						</span>
					</Button>
				)}
			</div>
		</div>
	);
};

ChannelButtonsPanelForVideo.propTypes = {
	centerChildren: PropTypes.node,
	className: PropTypes.string,
	handleChangeVolume: PropTypes.func,
	handleClickFullScreen: PropTypes.func,
	handleTogglePlaying: PropTypes.func,
	handleToggleSound: PropTypes.func,
	isFullscreen: PropTypes.bool,
	isPlaying: PropTypes.bool,
	isSoundMuted: PropTypes.bool,
	leftChildren: PropTypes.node,
	rightChildren: PropTypes.node,
	volume: PropTypes.number,
	shareVideoButton: PropTypes.node,
	miniPlayer: PropTypes.bool,
};

ChannelButtonsPanelForVideo.defaultProps = {
	centerChildren: null,
	className: '',
	handleChangeVolume: undefined,
	handleClickFullScreen: undefined,
	handleTogglePlaying: undefined,
	handleToggleSound: undefined,
	isFullscreen: false,
	isPlaying: false,
	isSoundMuted: false,
	leftChildren: null,
	rightChildren: null,
	volume: 50,
	shareVideoButton: null,
	miniPlayer: false,
};

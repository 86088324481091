import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { VideoSeekSlider } from 'react-video-seek-slider';
import { useTranslation } from 'react-i18next';

import 'react-video-seek-slider/styles.css';
import './VideoTimeline.scss';

const displayTime = (time) => {
	const h = Math.floor(time / 3600);
	const m = Math.floor((time - h * 3600) / 60);
	const s = Math.floor(time - h * 3600 - m * 60);

	let timeToDisplay = (h > 0) ? (`${h.toString().padStart(2, '0')}:`) : '';
	timeToDisplay += `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
	return timeToDisplay;
};

export const VideoTimeline = ({
	bufferedPercent,
	className,
	currentTime,
	duration,
	onChangeProgressTime,
	timelineRef,
	clipTimecodes,
}) => {
	const { t } = useTranslation();
	const startClipped = !!clipTimecodes?.[0];
	const endClipped = clipTimecodes?.[1] && clipTimecodes?.[1] < duration;
	const clipped = startClipped || endClipped;

	return (
		<div
			className={clsx(
				'VideoTimeline d-flex align-items-center flex-grow-1',
				className,
				{
					VideoTimeline__clip__start: startClipped,
					VideoTimeline__clip__end: endClipped,
				},
			)}
		>
			<span
				className={clsx(
					'mr-1 p-1 text-white',
					{ 'pe-2': !!clipTimecodes },
				)}
			>
				{displayTime(currentTime)}
			</span>
			<div ref={timelineRef} className="w-100 h-100">
				<VideoSeekSlider
					max={duration * 1000}
					currentTime={currentTime * 1000}
					bufferTime={duration * bufferedPercent * 1000}
					onChange={(time) => onChangeProgressTime(time / 1000)}
					offset={0}
					secondsPrefix="00:00:"
					minutesPrefix="00:"
					timeCodes={clipped ? [
						startClipped && {
							fromMs: 0,
							description: t('VideoTimeline.VideoTimeline.deletedPart'),
						},
						{
							fromMs: clipTimecodes[0] * 1000,
							description: t('VideoTimeline.VideoTimeline.finalVideo'),
						},
						endClipped && {
							fromMs: clipTimecodes[1] * 1000,
							description: t('VideoTimeline.VideoTimeline.deletedPart'),
						},
					].filter(Boolean) : undefined}
				/>
			</div>
			<span
				className={clsx(
					'ml-1 p-1 text-white',
					{ 'ps-2': !!clipTimecodes },
				)}
			>
				{displayTime(duration)}
			</span>
		</div>
	);
};

VideoTimeline.propTypes = {
	bufferedPercent: PropTypes.number,
	className: PropTypes.string,
	currentTime: PropTypes.number.isRequired,
	duration: PropTypes.number,
	onChangeProgressTime: PropTypes.func.isRequired,
	timelineRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
	clipTimecodes: PropTypes.arrayOf(PropTypes.number),
};

VideoTimeline.defaultProps = {
	bufferedPercent: 0,
	className: '',
	duration: 0.1, // 0.1 to avoid timeline being "complete" when video not loaded yet
	timelineRef: undefined,
	clipTimecodes: null,
};
